import 'bootstrap';
import './scss/app.scss';
import './scss/main.scss';
import './scss/main-responsive.scss';

import ScrollMagic from 'scrollmagic';
import Swiper from 'swiper';

require('jquery.easing');


// Mobile menu
$(function () {
    var toggler = document.querySelector('.navbar-menu-toggler'),
        menu = document.querySelector('.navbar-menu'),
        body = document.body;

    var a, b;

    function toggleMenu() {
        var that = this;

        if (!menu.classList.contains('menu-open')) {
            body.classList.add('menu-is-open');
            menu.style.display = 'block';
            setTimeout(function () {
                menu.classList.add('menu-open');
            }, 50);
        } else {
            body.classList.remove('menu-is-open');
            menu.classList.remove('menu-open');
            b = setTimeout(function () {
                menu.style.display = '';

            }, 650);
        }
    }

    toggler.addEventListener('click', toggleMenu, false);
});

// // Navbar scrolled
// $(function () {
//     var header = $('header'),
//         w = $(window);
//
//     function stickyNav() {
//         if (w.scrollTop() > 40) {
//             header.addClass('header-scrolled');
//         } else {
//             header.removeClass('header-scrolled');
//         }
//     }
//
//     stickyNav();
//     w.on('scroll', stickyNav);
// });

// Smooth scrolling
$(function () {
    // Select all links with hashes
    $('[data-scroll="smooth"], .navbar .nav-link').click(function (event) {
        // Figure out element to scroll to
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        // Does a scroll target exist?
        if (target.length) {
            // Only prevent default if animation is actually gonna happen
            event.preventDefault();
            $('html, body').animate({
                scrollTop: target.offset().top - $('header').innerHeight()
            }, 1000, 'easeInOutExpo', function () {
                // Callback after animation
                // Must change focus!
                var $target = $(target);
                $target.focus();
                if ($target.is(":focus")) { // Checking if the target was focused
                    return false;
                } else {
                    $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
                    $target.focus(); // Set focus again
                }
            });

            var menu = document.querySelector('.navbar-menu'),
                body = document.body;

            body.classList.remove('menu-is-open');
            menu.classList.remove('menu-open');
            setTimeout(function () {
                menu.style.display = '';
            }, 650);
        }
    });
});

// //Truncate text
// $(function () {
//     var blogBlock = $('.story-two');
//
//     function truncateText(item, truncateLength) {
//         $.each(item, function () {
//             var el = $(this),
//                 elP = el.find('p'),
//                 elPText = elP.text(),
//                 elPTextTrimmed = $.trim(elPText);
//
//             elP.html(elPTextTrimmed.substr(0, truncateLength) + '<a href="' + el.closest('.story-two').find('.story-two-href').attr('href') + '">... <span>continue reading</span></a>');
//         });
//     }
//
//     truncateText(blogBlock, 130);
// });

// //Sticky blog social
// $(function () {
//     var element = $('.blog-article-sticky-social'),
//         anchor = $('.blog-article-content-wrap'),
//         w = $(window),
//         lastScrollTop = 0;
//
//     if (element.length > 0) {
//         w.on('scroll', function () {
//             var st = $(this).scrollTop();
//
//             if (w.width() > 1279) {
//                 if (w.scrollTop() + 60 >= anchor.offset().top) {
//                     if (st > lastScrollTop) {
//                         element.css({
//                             position: 'fixed',
//                             top: '60px'
//                         });
//                     } else {
//                         element.css({
//                             position: 'fixed',
//                             top: '60px'
//                         });
//                     }
//
//                     lastScrollTop = st;
//                 } else if (w.scrollTop() <= anchor.offset().top - 60) {
//                     element.css({
//                         position: '',
//                         top: ''
//                     });
//                 }
//             } else if (w.scrollTop() <= anchor.offset().top - 60) {
//                 element.css({
//                     position: '',
//                     top: ''
//                 });
//             }
//         });
//     }
// });

// // Global Navigation
// $(function () {
//     var trigger = $('[data-trigger="dropdown"]'),
//         dropdownRoot = $('.dropdown-root'),
//         dropdownBackground = $('.dropdown-background'),
//         dropdownContainer = $('.dropdown-container'),
//         dropdownSection = $('.dropdown-section'),
//         body = $('body');
//
//     var a, b, c, i = 0, target;
//
//     function showNavigation(e) {
//         target = $('.dropdown-section[data-dropdown="' + $(e.target).data('dropdown') + '"]');
//
//         if (trigger.is(e.target)) {
//
//             if ($(e.target).data('dropdown') === '') {
//                 dropdownBackground.css({
//                     background: 'linear-gradient(to right, white 0%, white 60%, #f0f4f5 60%, #f0f4f5 100%)',
//                 });
//             } else {
//                 dropdownBackground.css({
//                     background: '',
//                 });
//             }
//
//             dropdownRoot.css({
//                 width: target.css('width'),
//             });
//
//             dropdownSection.removeClass('active');
//
//             dropdownSection.css({
//                 opacity: '',
//             });
//
//             dropdownRoot.show();
//
//             setTimeout(function () {
//                 dropdownRoot.css({
//                     transform: 'translate3d(' + ($(e.target).offset().left - (parseInt(target.css('width')) / 4) + ($(e.target).innerWidth() / 1)) + 'px, 0, 0)',
//                     transition: i <= 0 ? 'none' : '',
//                 });
//
//                 i++;
//
//                 dropdownBackground.css({
//                     height: target.innerHeight() + 'px',
//                     transform: 'none',
//                     opacity: '1',
//                 });
//
//                 dropdownContainer.css({
//                     height: target.innerHeight() + 'px',
//                 });
//
//                 target.css({
//                     opacity: '1',
//                     transform: 'none',
//                 });
//
//                 target.addClass('active');
//             }, 50);
//         } else {
//             clearTimeout(a);
//             clearTimeout(b);
//         }
//     }
//
//     function hideNavigation(e) {
//         a = setTimeout(function () {
//             dropdownSection.css({
//                 opacity: '',
//                 transform: '',
//             });
//
//             dropdownSection.removeClass('active');
//
//             dropdownBackground.css({
//                 transform: '',
//                 opacity: ''
//             });
//
//             b = setTimeout(function () {
//                 dropdownRoot.hide();
//                 dropdownRoot.css({
//                     width: '',
//                 });
//                 dropdownBackground.css({
//                     background: '',
//                 });
//             }, 250);
//         }, 200);
//     }
//
//     function initMenu() {
//         if ($(window).width() < 768) {
//             if (!body.hasClass('has-mobile-navigation')) {
//                 body.addClass('has-mobile-navigation');
//
//                 body.off('mouseover');
//
//                 body.on('click', function (e) {
//                     clearTimeout(a);
//                     clearTimeout(b);
//
//                     if (trigger.is(e.target) || trigger.has(e.target).length > 0) {
//                         target = $(e.target).parent().find(mobileNavigation);
//
//                         e.preventDefault();
//
//                         if (!target.hasClass('mobile-sub-navigation-open')) {
//                             $(e.target).addClass('active');
//                             target.addClass('mobile-sub-navigation-open');
//                         } else {
//                             $(e.target).removeClass('active');
//                             target.removeClass('mobile-sub-navigation-open');
//                         }
//                     } else {
//                         $(e.target).removeClass('active');
//                     }
//                 });
//             }
//         } else {
//             body.removeClass('has-mobile-navigation');
//
//             body.off('click');
//
//             body.on('click', function (e) {
//                 clearTimeout(a);
//                 clearTimeout(b);
//
//                 if (trigger.is(e.target) || trigger.has(e.target).length > 0) {
//                     return true;
//                 }
//             });
//
//             body.on('mouseover', function (e) {
//                 clearTimeout(a);
//                 clearTimeout(b);
//
//                 if (trigger.is(e.target) || trigger.has(e.target).length > 0 || dropdownSection.is(e.target) || dropdownSection.has(e.target).length > 0) {
//                     showNavigation(e);
//                 } else {
//                     hideNavigation(e);
//                 }
//             });
//         }
//     }
//
//     initMenu();
//     $(window).on('resize', initMenu);
// });

// Reveal on scroll
$(function () {
    // init controller
    var controller = new ScrollMagic.Controller();

    // build scenes
    var revealElements = document.querySelectorAll("[data-animate='true']");

    for (var i = 0; i < revealElements.length; i++) { // create a scene for each element
        var scene = new ScrollMagic.Scene({
            triggerElement: revealElements[i], // y value not modified, so we can use element as trigger as well
            // offset: $(window).height() / 12,	// start a little later
            offset: 40,	// start a little later
            triggerHook: 1,
        })
            .setClassToggle(revealElements[i], "animate") // add class toggle
            .reverse(false)
            .addTo(controller);

        function callback() {
            $('section, .section').each(function () {
                $(this).find($(revealElements)).each(function (i) {
                    var that = $(this);

                    that.css({
                        transitionDelay: 100 * i + 'ms',
                    });

                    if (that.hasClass('btn')) {
                        setTimeout(function() {
                            that.attr('data-animate', 'false');
                            that.css({
                                transitionDelay: '',
                            });
                            that.removeClass('animate');
                        }, (100 * i) * 3);
                    }
                });
            });
        }

        // add listeners
        scene.on("enter", callback);
    }
});

